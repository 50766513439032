<template>
  <!--begin::Sidebar-->
  <div class="flex-column flex-lg-row-auto w-100 w-xl-325px mb-10">
    <!--begin::Card-->
    <div class="card card-flush">
      <!--begin::Card body-->
      <div class="card-body pt-0 p-10">
        <!--begin::Summary-->
        <div class="d-flex flex-column pt-10 mb-10">
          <!--begin::Name-->
          <router-link to="dashboard" class="fs-2 text-gray-800 text-hover-primary fw-bolder mb-1">
            {{ organisation.name }}
          </router-link>
          <!--end::Name-->
        </div>
        <!--end::Summary-->
        <!--begin::Menu-->
        <ul class="menu menu-column menu-pill menu-title-gray-700 menu-bullet-gray-300
          menu-state-bg menu-state-bullet-primary fw-bolder fs-5 mb-10">
          <SidebarLink :to="getLink('dashboard')" title="Kokpit" />
          <SidebarLink :to="getLink('apps')" title="Aplikacje" />
          <SidebarLink :to="getLink('users')" title="Użytkownicy" />
        </ul>
        <!--end::Menu-->
      </div>
      <!--end::Card body-->
    </div>
    <!--end::Card-->
  </div>
  <!--end::Sidebar-->
</template>

<script>
import SidebarLink from './SidebarLink.vue';

export default {
  name: 'Sidebar',
  inject: ['$profile', '$organisation'],
  props: ['id'],
  data() {
    return {
      userId: false,
      urlPrefix: ''
    }
  },
  mounted() {
    if (this.id)
    {
      this.userId = this.id;
      this.urlPrefix = '/organisations/' + this.id;
    }
    else
    {
      this.userId = 'me';
      this.urlPrefix = '/dashboard';
    }
  },
  methods: {
    getLink: function(link) {
      if (this.profile.is_admin)
      {
        if (link == 'dashboard') {
          return '/admin/organisations/' + this.id;
        }
        return '/admin/organisations/' + this.id + '/' + link;
      }
      if (link == 'dashboard') {
        return '/dashboard';
      }
      else return '/dashboard/' + link;
    }
  },
  computed: {
    organisation() {
      return this.$organisation();
    },
    profile() {
      return this.$profile();
    }
  },
  components: {
    SidebarLink
  }
};
</script>

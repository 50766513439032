<template>
  <li class="menu-item mb-1">
    <router-link v-bind:to="to" class="menu-link px-6 py-4">
      <span class="menu-bullet">
        <span class="bullet"></span>
      </span>
      <span class="menu-title">{{ title }}</span>
      <small v-if="requestsCount > 0" class="mx-2 badge badge-square badge-secondary">{{ requestsCount }}</small>
    </router-link>
  </li>
</template>

<script>
export default {
  name: 'SidebarLink',
  inject: ['requestsCount'],
  props: ['to', 'title']
};
</script>
